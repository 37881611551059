import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";

// Icons
import { Next, Prev } from "../components/icons/arrows";

const Page = styled.div`
  margin: 25px 0 170px 0;

  & .title-container {
    margin: 0 0 60px 0;

    & h1,
    & p {
      margin: 0;
      line-height: 30px;

      &.dates {
        line-height: 26px;
      }
    }

    @media (max-width: 768px) {
      & h1,
      & p {
        text-align: center;
      }

      & h1 {
        margin: 0 0 10px 0;
      }

      & .subtitle > p,
      & .dates {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.04em;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 95px 0;
  }
`;

const Grid = styled.div`
  & .body-text {
    & p {
      margin: 0;
      text-indent: 99px;

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        text-indent: 35px;
      }
    }

    &.first {
      & p {
        text-indent: unset;
      }
    }
  }

  & .body-text,
  & .credit-text {
    max-width: 820px;

    // & p {
    //   &:first-of-type {
    //     margin-top: 0;
    //   }
    //   &:last-of-type {
    //     margin-bottom: 0;
    //   }
    // }
  }

  & .read-more-link {
    margin: 25px 0 0 0;

    transition: 250ms color ease;

    &:hover {
      color: #163262;

      & svg {
        transition: 250ms stroke ease, 250ms fill ease;

        & path {
          &:first-of-type {
            stroke: #163262;
          }

          &:last-of-type {
            fill: #163262;
          }
        }
      }
    }

    & p {
      margin: 0;
      // color: #163262;

      &:first-of-type {
        display: inline-block;
      }

      & svg {
        width: 27px;
        height: auto;

        margin: 0 0 0 15px;
      }
    }
  }

  & .image {
    max-width: 820px;
    margin: 30px 0;

    & img {
      max-width: 620px;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      margin: 25px 0;
    }
  }

  & .credit-text {
    margin: 27px 0 0 0;

    & > div {
      max-width: 615px;

      & p {
        font-size: 11px;
        line-height: 17px;
        letter-spacing: 0.05em;
      }
    }
  }

  @media (max-width: 768px) {
    & .body-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const ArticleNavigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 70px 0 0 0;

  & a {
    transition: 250ms color ease;

    &:first-of-type {
      margin: 0 40px 0 0;
    }

    &:hover {
      color: #163262;

      & svg {
        & path {
          transition: 250ms stroke ease, 250ms fill ease;

          &:first-of-type {
            stroke: #163262;
          }

          &:last-of-type {
            fill: #163262;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin: 35px 0 0 0;

    justify-content: center;
  }
`;

const NewsArticle = ({ data, location }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  // Tags and filters
  const [activeTag, setActiveTag] = useState(`all`);

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.activeTag !== undefined) {
          setActiveTag(location.state.activeTag);
        }
      }
    }
  }, [location]);

  const content = data.prismicNewsArticle.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div
          className={`body-text ${index === 0 ? `first` : `next`}`}
          key={`single_body_text_${index}_${content.id}`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "image") {
      return (
        <div className="image" key={`single_image_${index}_${content.id}`}>
          {content.primary.image.fluid !== null && (
            <img
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
              loading={`lazy`}
            />
          )}
        </div>
      );
    }

    if (content.slice_type === "credit") {
      return (
        <div
          className="credit-text"
          key={`single_credit_text_${index}_${content.id}`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "read_more_link") {
      return (
        <div
          className="read-more-link"
          key={`single_read_more_link_${index}_${content.id}`}
        >
          <a href={content.primary.link.url} target="_blank" rel="noreferrer">
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: content.primary.read_more_link.html,
                }}
              />

              <Next />
            </p>
          </a>
        </div>
      );
    }
  });

  // All Article Links
  const allArticles = data.allPrismicNewsArticle.edges.map(
    article => article.node.uid
  );

  // Current article
  const currentArticleNumber = allArticles.indexOf(data.prismicNewsArticle.uid);

  // Next article link
  const nextArticleUid =
    currentArticleNumber + 1 < allArticles.length
      ? allArticles[currentArticleNumber + 1]
      : allArticles[0];

  // Prev article link
  const prevArticleUid =
    currentArticleNumber === 0
      ? allArticles[allArticles.length - 1]
      : allArticles[currentArticleNumber - 1];

  return (
    <>
      <PageSEO
        title={data.prismicNewsArticle.data.title.text}
        description={data.prismicNewsArticle.data.subtitle.text}
        image={data.prismicNewsArticle.data.image.url}
        url={`https://yinkashonibare.com${data.prismicNewsArticle.url}`}
      />

      <Page>
        <div className="title-container">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: data.prismicNewsArticle.data.title.html,
            }}
          />
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: data.prismicNewsArticle.data.subtitle.html,
            }}
          />
          <p className="dates">{data.prismicNewsArticle.data.dates}</p>
        </div>
        <Grid>{content}</Grid>

        <ArticleNavigation>
          <Link
            to={`/news/`}
            state={{
              activeTag: activeTag,
              articleId: data.prismicNewsArticle.id,
            }}
          >
            <Prev />
          </Link>
          <Link to={`/news/${nextArticleUid}`}>
            <Next />
          </Link>
        </ArticleNavigation>
      </Page>
    </>
  );
};

export default withPreview(NewsArticle);

export const query = graphql`
  query NewsArticle($uid: String!) {
    allPrismicNewsArticle(
      sort: { fields: data___published_date, order: DESC }
    ) {
      edges {
        node {
          url
          uid
        }
      }
    }
    prismicNewsArticle(uid: { eq: $uid }) {
      uid
      id
      data {
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        dates
        image {
          url
        }
        body {
          ... on PrismicNewsArticleBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicNewsArticleBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicNewsArticleBodyCredit {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicNewsArticleBodyReadMoreLink {
            id
            slice_type
            primary {
              read_more_link {
                html
              }
              link {
                url
              }
            }
          }
        }
      }
      url
    }
  }
`;
