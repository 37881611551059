import React from "react";

export const Next = () => (
  <svg
    width="40"
    height="17"
    viewBox="0 0 40 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 8.55298L3.42727e-07 8.55298"
      stroke="#4383EF"
      strokeMiterlimit="10"
    />
    <path
      d="M40 8.45743L40 8.17093L40 7.97993C39 7.88443 38 7.69344 37 7.21594C36.1 6.83395 35.3 6.26096 34.6 5.59247C33.9 4.92398 33.4 4.06449 33 3.205C32.7 2.34552 32.5 1.39053 32.5 0.435547L32.1 0.435547L31.7 0.435547L31.7 4.25549L31.7 8.07543L31.7 8.55292L31.7 12.3729L31.7 16.1928L32.1 16.1928L32.5 16.1928C32.5 15.2378 32.7 14.2828 33 13.4234C33.4 12.5639 33.9 11.7044 34.6 11.0359C35.3 10.2719 36.1 9.69891 37 9.31691C38 8.93492 39 8.64842 40 8.64842L40 8.45743Z"
      fill="#4383EF"
    />
  </svg>
);

export const Prev = () => (
  <svg
    width="40"
    height="17"
    viewBox="0 0 40 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 8.07544L40 8.07544" stroke="#4383EF" strokeMiterlimit="10" />
    <path
      d="M-3.67176e-07 8.1709L-3.54062e-07 8.4574L-3.4532e-07 8.6484C0.999998 8.7439 2 8.9349 2.99999 9.41239C3.89999 9.79439 4.69999 10.3674 5.39999 11.0359C6.09999 11.7044 6.59999 12.5639 6.99999 13.4234C7.29999 14.2829 7.49999 15.2379 7.49999 16.1929L7.89999 16.1929L8.29999 16.1929L8.29999 12.3729L8.29999 8.5529L8.29999 8.0754L8.29999 4.25541L8.29999 0.435424L7.89999 0.435424L7.49999 0.435424C7.49999 1.39042 7.29999 2.34542 6.99999 3.20491C6.59999 4.06441 6.09999 4.92391 5.39999 5.59241C4.69999 6.3564 3.89999 6.9294 2.99999 7.3114C2 7.6934 0.999998 7.9799 -3.75918e-07 7.9799L-3.67176e-07 8.1709Z"
      fill="#4383EF"
    />
  </svg>
);
